import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';

interface AnnouncementBarContextType {
  setAnnouncementBarHeight: (height: number | null) => void;
  announcementBarHeight: number | null;
  showAnnouncementBar: boolean | null;
  setShowAnnouncementBar: (show: boolean | null) => void;
}

const AnnouncementBarContext = createContext<AnnouncementBarContextType | undefined>(undefined);

export const useAnnouncementBar = (): AnnouncementBarContextType => {
  const context = useContext(AnnouncementBarContext);
  if (!context) {
    throw new Error('useAnnouncementBar must be used within an AnnouncementBarProvider');
  }
  return context;
};

interface AnnouncementBarProviderProps {
  children: ReactNode;
}

const AnnouncementBarProvider: React.FC<AnnouncementBarProviderProps> = ({ children }) => {
  const [showAnnouncementBar, setShowAnnouncementBar] = useState<boolean | null>(null);
  const [announcementBarHeight, setAnnouncementBarHeight] = useState<number | null>(null);

  useEffect(() => {
    if (announcementBarHeight !== null) {
      setAnnouncementBarHeight(announcementBarHeight);
    } else {
      setAnnouncementBarHeight(null);
    }
  }, [announcementBarHeight, showAnnouncementBar]);

  return (
    <AnnouncementBarContext.Provider
      value={{
        setAnnouncementBarHeight,
        announcementBarHeight,
        showAnnouncementBar,
        setShowAnnouncementBar,
      }}
    >
      {children}
    </AnnouncementBarContext.Provider>
  );
};

export default AnnouncementBarProvider;
