import { GatsbyBrowser } from 'gatsby';

import { AuthProvider } from 'contexts/AuthProvider';
import { ThemeProvider } from 'contexts/ThemeProvider';
import { FaqSearchProvider } from 'contexts/FaqSearchProvider';
import { ModalProvider } from 'contexts/ModalProvider';
import AnnouncementBarProvider from 'contexts/AnnouncementBarContext';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
  <ThemeProvider>
    <AuthProvider>
      <AnnouncementBarProvider>
        <ModalProvider>
          <FaqSearchProvider>{element}</FaqSearchProvider>
        </ModalProvider>
      </AnnouncementBarProvider>
    </AuthProvider>
  </ThemeProvider>
);
